.menu-container{
    padding:10px 15px 10px 15px;
    // min-height: 44px !important; 
    height: 8vh;
    box-shadow: 0px 3px 16px 0px #00000014;
    background-color: #FFFFFF !important;
}

.menu-text{
    color:#A2A2A2;
    font-size: 14px;
    font-weight: 600;
    line-height: 14px;
    text-align: center;
    align-items: center;
}

.menu-icon{
    cursor: pointer;
}

.title{
    color: #9FA8B5;
    font-size: 20px;
    font-weight: 500;
    line-height: 24.2px;
    font-family: 'Inter_Medium';
}

.MuiListItemIcon-root{
   min-width: 35px !important;
}

.MuiListItemButton-root{
    margin: 5px 20px !important;
    border-radius: 0px !important;

}

.sidebarList .borderLeftShow{
    border-left: 3px solid #292D32 !important;
    height: 41.52px;
}

.sidebarList .borderLeftWithoutShow{
    border-left: 3px solid transparent !important;
    height: 41.52px;
}

.Item-text .MuiTypography-root{
    color:#9A9A9A;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.51px;
    font-family: 'Akkurat' !important;
}

.Item-logout .MuiTypography-root{
    color:#292D32;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.51px;
    font-family: 'Akkurat' !important;
}

.active-menu-item{
    background-color: #292D32 !important;
    color: #FFFFFF !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.51px;
    font-family: 'Akkurat' !important;
}
  
.sidebarList {
    display: flex;
    flex-direction: column;
    min-height: 85vh; // Full viewport height to ensure the sidebar spans the whole screen
    position: relative;
    // overflow-y: auto;
  }
  
//   .sidebarList .sidebarBottom {
//     margin-top: auto; // This will push the "Logout" item to the bottom
//   }

  .sidebarList .sidebarBottom {
    margin: 0 auto;
    position: absolute;
    bottom: 0;
  }


.active-menu-item .Item-text .MuiTypography-root {
    color: #FFFFFF !important;
}
  
.active-menu-item .MuiListItemIcon-root {
    color: white; /* Active icon color */
}

.drawer-menu .MuiDrawer-paper{
    width:14%;
}

.submenuList {
    padding-left: 0px;
    padding: 0px;
    margin: 0px;
    width:264px;
    .active-submenu-item {
      background-color: transparent; // Adjust as needed
      color: #911E59 !important;
    }
}

.active-submenu-item .Item-text .MuiTypography-root {
    color: #911E59 !important;
}




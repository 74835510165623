////////////// background color //////////////

.bc-FF0000 {
  background-color: #ff0000 !important;
}
.bc-FFFFFF {
  background-color: #ffffff !important;
}
.bc-F2F3F6 {
  background-color: #f2f3f6 !important;
}
.bc-808080 {
  background-color: #808080 !important;
}
.bc-292D32 {
  background-color: #292d32 !important;
}
.bc-F6F6F6 {
  background-color: #f6f6f6 !important;
}

////////////// background color //////////////

////////////// font color //////////////

.fc-FFFFFF {
  color: #ffffff;
}
.fc-2C2C2C {
  color: #2c2c2c;
}
.fc-6B788E {
  color: #6b788e;
}
.fc-292D32 {
  color: #292d32;
}
.fc-9A9A9A {
  color: #9a9a9a;
}
.fc-404040 {
  color: #404040;
}
.fc-A4AAB2 {
  color: #a4aab2;
}
.fc-A2A2A2 {
  color: #a2a2a2;
}
.fc-818488 {
  color: #818488;
}
.fc-AF3C29 {
  color: #af3c29;
}
.fc-1E1E1E {
  color: #1e1e1e;
}
.fc-1A1A1A {
  color: #1a1a1a;
}
.fc-787878 {
  color: #787878;
}
.fc-EE3232 {
  color: #ee3232;
}

///////////////// font color ///////////

////////////// font Size //////////////

.fs-12 {
  font-size: 12px;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.fs-18 {
  font-size: 18px;
}
.fs-20 {
  font-size: 20px;
}
.fs-24 {
  font-size: 24px;
}
.fs-28 {
  font-size: 28px;
}
.fs-32 {
  font-size: 32px;
}
.fs-40 {
  font-size: 40px;
}

///////////////// font Size ///////////

////////////// font Weight //////////////

.fw-400 {
  font-weight: 400;
}
.fw-600 {
  font-weight: 600;
}

///////////////// font Weight ///////////

////////////// font Family //////////////

.ff-Akkurat_Bold {
  font-family: Akkurat_Bold;
}
.ff-Akkurat_Italic {
  font-family: Akkurat_Italic;
}
.ff-Akkurat_Light {
  font-family: Akkurat_Light;
}
.ff-Akkurat {
  font-family: Akkurat;
}

///////////////// font Family ///////////

.commonEnContainer {
  // margin-left: 14% !important;
  height: 92vh;
  overflow-y: auto;
  padding: 30px !important;
}

.commonArContainer {
  // margin-right: 14% !important;
  height: 92vh;
  overflow-y: auto;
  padding: 30px !important;
}

.borderBottom {
  border-bottom: 1.2px solid #292d32;
}

.bColor-E3E3E3 {
  color: #e3e3e3;
}

.bColor-DDDDDD {
  color: #dddddd;
}

.bColor-292D32 {
  border-color: #292d32 !important;
}

.listHeader {
  background-color: #e4e4e4;
  border: 1px solid #e5e5e5;
  height: 38px;
}

.listCustomHeader {
  background-color: #ffffff;
  border-bottom: 1px solid #e5e5e5;
  height: 38px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
}

.listAppointment {
  height: 72px !important;
  border-bottom: 1px solid #e5e5e5 !important;
  padding: 4px 8px !important;
}

.listCampaign {
  border-bottom: 1px solid #e5e5e5 !important;
  padding: 20px 8px !important;
}

.imageBorder {
  border: 1px dashed #dddddd;
  height: 48px;
}

.imageRedBorder {
  border: 1px dashed #ee3232;
  height: 48px;
}

.imageBorderWithoutDashed {
  border: 1px solid #dddddd;
  height: 48px;
}

.pt-15 {
  padding-top: 15px !important;
}

.toolbarClassName {
  background-color: #f5f5f5;
}

.wrapperClassName {
  border: 1px solid #dddddd;
  padding: 10px;
  border-radius: 0px;
}

.editorClassName {
  min-height: 82px;
  max-height: 150px;
  overflow-y: scroll;
  padding: 10px;
}

.public-DraftStyleDefault-block {
  margin: 0px !important;
}

.changeIconColor {
  // filter: opacity(1) drop-shadow(0px 60px 0px gray);
  // position: relative;
  // top: -60.5px;
  opacity: 0.5;
  font-family: 'Akkurat_Bold';
}

.AvtarImageIcon {
  height: 88px;
  width: 88px;
  border-radius: 50%;
  background-color: #eeeef0;
  position: relative;
}

.profileIcon {
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
}

.listBranch {
  border: 1px solid #eaeaea;
}

.bussinessImages {
  border: 1px dashed #dddddd;
  height: 122px;
}

//////////Remove Input Number Suggestion/////////
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}
//////////Remove Input Number Suggestion/////////

.image-item .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #00000099;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  font-size: 24px;
  font-weight: 400;
  font-family: 'Akkurat_Bold';
}

/////////starRating//////////
.starfill {
  .MuiSvgIcon-root {
    color: #ea9588 !important;
  }
}

.starUnfill {
  .MuiSvgIcon-root {
    color: #cbd5e1;
  }
}
///////starRating///////

.badge.ambiencePill {
  padding: 9px 16px !important;
  color: #292d32 !important;
  border: 1px solid #292d32 !important;
  background-color: transparent !important;
}

.weekdays {
  width: 24px;
  align-items: center;
  text-align: center;
  padding: 3px;
}

.weekdaysCampaign {
  width: 36px;
  align-items: center;
  text-align: center;
  padding: 6px;
  border: 1px solid #dddddd;
}

.bannerImage {
  border: 1px solid #eaeaea;
  height: 160px;
}

/////////////////////////popOver////////////////////////////
.Popover {
  .MuiSvgIcon-root {
    color: #1e1e1e;
  }

  .MuiPaper-root.MuiPopover-paper {
    box-shadow: 0px 4px 8px 0px #dbe0e980 !important;
  }

  .group {
    color: #1e1e1e !important;
  }

  .group:hover {
    background: #fff2f8 !important;
  }

  .group:hover .MuiSvgIcon-root, /* To target the MUI icon */
   .group:hover p {
    color: #911e59 !important;
  }
}

/////////////////////////popOver/////////////////////////////

///////geoSuggestInput///////////////////////////////////////
.businessLabel {
  font-family: 'Akkurat_Bold';
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 8px;
  margin-top: 32px !important;
  color: #292d32;
}

.geosuggestInput {
  border: 1px solid #dddddd;
  border-radius: 0px;
  height: 50px;
  padding: 15px;
  font-family: Akkurat;
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  position: relative;
  width: 100%;

  .geosuggest__input-wrapper {
    height: 100%;
    width: 100%;
  }

  .geosuggest__input {
    width: 100%;
    outline: none !important;
    height: 100%;
  }

  .geosuggest__input:focus {
    border: none;
  }

  .geosuggest__input::placeholder {
    color: #bcbcbc;
  }

  .geosuggest__suggests-wrapper {
    background-color: #ffffff;
    max-width: 90%;
    z-index: 2;
    position: absolute;

    .geosuggest__item {
      padding: 5px 15px 5px 0px;
      font-size: 18px;
      list-style: none;
      cursor: pointer;
    }
    .geosuggest__item:hover,
    .geosuggest__item:focus {
      background: #bfe9f1;
    }
  }

  .geosuggest__input:focus,
  input:focus {
    outline: none;
    border: none;
  }

  .geosuggest__suggests--hidden {
    display: none !important;
    border: none !important;
    outline: none !important;
  }
}

///////geoSuggestInput///////////////////////////////////////

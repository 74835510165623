@tailwind base;
@tailwind components;
@tailwind utilities;


/* ////////////////////////// Akkurate Font ////////////////// */

@font-face {
    font-family: Akkurat_Bold;
    src:url('./assets/fonts/akkurate/FontsFree-Net-Akkurat-Bold.ttf');
}
@font-face {
    font-family: Akkurat_Italic;
    src:url('./assets/fonts/akkurate/FontsFree-Net-Akkurat-Italic.ttf');
}
@font-face {
    font-family: Akkurat_Light;
    src:url('./assets/fonts/akkurate/FontsFree-Net-Akkurat-Light.ttf');
}
@font-face {
    font-family: Akkurat;
    src:url('./assets/fonts/akkurate/FontsFree-Net-Akkurat.ttf');
}

/* ////////////////////////// Akkurate Font ////////////////// */


.navbarEn{
  background-color:transparent;
  height: 8vh;
  // margin-left: 14%;
}
.navbarAr{
  background-color:transparent;
  height: 8vh;
  // margin-right: 14%;
}

.navbar-light .navbar-nav .nav-link {
    color: #334155;
    margin-right: 20px;
  }
  
  .navbar-light .navbar-brand {
    font-weight: 700;
    font-size: 1.2rem;
  }
  
  .btn-outline-primary {
    border-color: #8a004f;
    color: #8a004f;
  }
  
  .btn-primary {
    background-color: #8a004f;
    border-color: #8a004f;
  }

  .underline-text {
    font-weight: bold !important;              
    text-decoration: underline !important;       
    text-underline-offset: 3px !important;
  }
.rbc-event {
    border: 1px solid #292D32;
    border-radius: 4px;
    padding: 5px;
    background-color: #F3F3F3;
    color: #292D32;
    font-weight: 400;
    height: 50px !important;
    margin-top: 5px !important;
 }
  
 .rbc-time-content {
    background-color: #FFFFFF;
    border-top: none !important;
 }
  
 .rbc-timeslot-group {
    min-height: 60px; /* Customize slot height */
 }

 .rbc-time-header{
    display: none;
 }
  
 .rbc-toolbar {
    display: none; /* Hide default toolbar */
 }

 .rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 0;
    top: 0;
}

.rbc-events-container {
    margin-left: 20px !important;
}

.rbc-time-content > * + * > * {
    border-left: none !important;
}

.sunday {
   color: #292D32 !important;
 }
 
 .saturday {
   color: #292D32 !important;
 }

 .today {
   background-color: transparent !important;
   color: #292D32 !important;
   border: 1px solid #292D32 !important;
 }

 .selected {
   background-color: #292D32 !important;
   color: #FFFFFF !important;
 }

 .react-calendar__tile {
  //  margin: 2px !important;
   transition: all 0.3s ease !important;
 }

 .react-calendar {
   border: none !important;
   box-shadow: none !important;
 }
